export const setRangeMax = (arr: NodeListOf<HTMLDivElement>, input: HTMLInputElement, perView: number) => {
  const totalSlides = arr.length;

  if (totalSlides <= perView) {
    input.max = '0';
  } else {
    const steps = Math.ceil(totalSlides - perView);
    input.max = steps.toString();
  }

  setRangeThumbWidth(Number(input.max), input);
}

const setRangeThumbWidth = (max: number, input: HTMLInputElement) => {
  const width = max === 1 ? 100 : 200 / max;
  input.style.setProperty('--thumb-size', `${width}px`);
}
