import Glide from '@glidejs/glide';
import { setRangeMax } from '../../../helpers/carouselRange';

const featureCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.feature-carousel__inner');

if (featureCarousels.length > 0) {
    featureCarousels.forEach((item) => {
        var glide = new Glide(item, {
            type: 'slider',
            perView: 3.52,
            gap: 20,
            bound: true,
            rewind: false,
            breakpoints: {
                1200: {
                    perView: 2.5,
                },
                760: {
                    perView: 1.7,
                },
                550: {
                    perView: 1.2,
                },
            },
        });

        glide.mount();

        const carouselRange = item.querySelector('.feature-carousel__range') as HTMLInputElement;

        carouselRange.addEventListener('input', (e) => {
            setTimeout(() => {
                glide.go(`=${carouselRange.value}`);
            }, 500);
        });

        glide.on('move.after', () => {
            const currentIndex = glide.index;
            carouselRange.value = currentIndex;
        });

        const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.feature-carousel__item');
        setRangeMax(slides, carouselRange, glide.settings.perView);
    });
}
