import Glide from '@glidejs/glide'

const testimonialsCarousels = document.querySelectorAll(".testimonials__inner")

const testimonialsCarouselsArray = Array.from(testimonialsCarousels) || []

if(testimonialsCarousels){

  testimonialsCarouselsArray.forEach(item => {

  var glide = new Glide(item, {
    type: 'slider',
    perView: 1,
    gap: 60,
    rewind: true,
    touchAngle:0,
    breakpoints: {
      480: {
        perView: 1.2,
        gap: 20,
      },
    }
  })
  
  glide.mount()

  const carouselRange = item.querySelector(".testimonials__range") as HTMLInputElement

  carouselRange.addEventListener("input", e => {
      setTimeout(() => {
        glide.go(`=${carouselRange.value}`)
      }, 500);
  })

  glide.on('move.after', () =>{
    const currentIndex = glide.index;
    carouselRange.value = currentIndex
  })
  })
}

if(testimonialsCarousels){
  testimonialsCarouselsArray.forEach(item => {
    const slides = item.querySelectorAll(".testimonials__item")
    const carouselRange = item.querySelector(".testimonials__range") as HTMLInputElement

      if(slides.length < 2) {
        const buttons = item.querySelector(".testimonials__controls") as Element
        buttons.classList.add("hidden")

        const rangeContainer = item.querySelector(".testimonials__range-container") as Element
        rangeContainer.classList.add("hidden")

        return
    }
  
    setRangeMax(slides, carouselRange)
  })
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 1).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");
}